import { Chain } from "viem";
import { ChainsLogo } from "../../web3/chains";
import useWalletAccount from "../../web3/hooks/useWalletAccount";
import { useSwitchChain } from "wagmi";
import { useBridgeContext } from "@/context/bridgeProvider";

interface prop {
  closeModalHandler: () => void;
  networks: Chain[];
  isDestination: boolean;
}
const NetworksModal = ({
  closeModalHandler,
  networks,
  isDestination,
}: prop) => {
  const { handleReset, setDestinationChain, destinationChain } =
    useBridgeContext();
  const { connectedChainId } = useWalletAccount();
  const { switchChain } = useSwitchChain();

  const handleSwitchChain = (chain: Chain) => {
    closeModalHandler();
    if (isDestination && setDestinationChain) {
      setDestinationChain(chain);
      return;
    }
    handleReset();
    switchChain({ chainId: chain.id });
  };

  return (
    <div className="flex gap-6 flex-wrap">
      {networks.map((chain) => (
        <div
          onClick={() => handleSwitchChain(chain)}
          key={chain.id}
          className={` ${
            isDestination &&
            destinationChain?.id === chain.id &&
            "border rounded-lg"
          } ${
            !isDestination &&
            connectedChainId === chain.id &&
            "border rounded-lg"
          } flex md:w-[calc(50%_-_1rem)]  lg:w-[calc(33.333%_-_1rem)]  py-5 flex-col items-center justify-center gap-2 cursor-pointer w-full hover:bg-gray-900 rounded-lg`}
        >
          <img className="rounded-full" src={ChainsLogo[chain.id]} width={30} />
          <div>{chain.name}</div>
        </div>
      ))}
    </div>
  );
};

export default NetworksModal;
