"use client";
import { useState } from "react";
import { useBridgeContext } from "@/context/bridgeProvider";
import useWalletAccount from "../../../web3/hooks/useWalletAccount";
import WalletConnectButton from "../../../web3/components/WalletConnectButton";
import { toast, ToastContainer } from "react-toastify";
import { useSwitchChain } from "wagmi";

const Actions = () => {
  const { account, connectedChainId } = useWalletAccount();
  const {
    userAllowance,
    insufficientBalance,
    bridgeAmount,
    handleBridge,
    handleApprove,
    selectedTokenToBridge,
    handleReset,
    setStatus,
    setBridgeHash,
  } = useBridgeContext();

  const { switchChain } = useSwitchChain();

  let isApproved = Number(userAllowance) >= Number(bridgeAmount);

  const [approvalState, setApprovalState] = useState<boolean>(false);

  const [bridgeLoading, setBridgeLoading] = useState<boolean>(false);

  const handleApproveToken = async () => {
    try {
      setApprovalState(true);
      await handleApprove();
      setApprovalState(false);
      toast.success("Successfully Approved!", {
        position: "bottom-right",
      });
      isApproved = true;
    } catch (e) {
      console.error("Approval error:", e);
      toast.error("Approval failed", {
        position: "bottom-right",
      });
      setApprovalState(false);
    }
  };

  const handleBridgeToken = async () => {
    try {
      setBridgeLoading(true);
      setStatus(null);
      setBridgeHash(null);
      await handleBridge();
      setBridgeLoading(false);
      toast.success("Successfully Bridged!", {
        position: "bottom-right",
      });
      // handleReset();
    } catch (e: any) {
      let errorMessage = "Bridge failed.";

      if (
        e.code === "ACTION_REJECTED" ||
        e.message?.includes("User denied transaction signature")
      ) {
        errorMessage = "User denied transaction signature.";
      } else if (e.message?.includes("exceeds the balance of the account")) {
        errorMessage =
          "Insufficient funds:Insufficient balance to complete this transaction.";
      } else if (e.message?.includes("intrinsic gas too low")) {
        errorMessage = "Gas limit too low. Try increasing the gas limit.";
      } else if (e.reason) {
        errorMessage = `Bridge failed: ${e.reason}`;
      } else if (e.data?.message) {
        errorMessage = `Bridge failed: ${e.data.message}`;
      } else if (e.message) {
        errorMessage = `Bridge failed: ${e.message}`;
      }

      setBridgeLoading(false);
      toast.error(`Bridge failed: ${errorMessage || "Unknown error"}`, {
        position: "bottom-right",
      });
    }
  };

  return (
    <div>
      <ToastContainer />
      {!account ? (
        <WalletConnectButton />
      ) : !isApproved && !insufficientBalance && bridgeAmount ? (
        <button
          onClick={handleApproveToken}
          className={`radial-gradient-button ${
            approvalState ? "opacity-60" : ""
          }`}
          disabled={insufficientBalance || !bridgeAmount || approvalState}
        >
          {approvalState ? "Approving..." : "Approve"}
        </button>
      ) : account &&
        selectedTokenToBridge &&
        selectedTokenToBridge.chainId !== connectedChainId ? (
        <button
          onClick={() =>
            switchChain({ chainId: selectedTokenToBridge.chainId })
          }
          className="radial-gradient-button cursor-pointer"
        >
          Switch Network
        </button>
      ) : (
        <button
          onClick={handleBridgeToken}
          disabled={
            insufficientBalance ||
            !bridgeAmount ||
            Number(bridgeAmount) <= 0 ||
            bridgeLoading
          }
          className={`radial-gradient-button cursor-pointer ${
            (insufficientBalance ||
              !bridgeAmount ||
              Number(bridgeAmount) <= 0 ||
              bridgeLoading) &&
            "opacity-60"
          }`}
        >
          {bridgeLoading ? "Bridging..." : "Bridge"}
        </button>
      )}
    </div>
  );
};

export default Actions;
