import { ChainsLogo } from "@/web3/chains";
import useWalletAccount from "../../../web3/hooks/useWalletAccount";
import { useBridgeContext } from "@/context/bridgeProvider";
import { useFetchOFTBalances } from "@/web3/hooks/useFetchOFTBalances";
import { useEffect, useState } from "react";
import { formatNumber, fromWei } from "@/utils/web3BigNumbers";
// import { realTokenList } from "@/constants/contractAddresses";

const BridgeAmountInput = () => {
  const {
    userOftBalances,
    isFetchingOftBalance,
    isOftBalanceFetched,
    refetchOftBalance,
  } = useFetchOFTBalances();

  const {
    selectedTokenBalance,
    selectedTokenToBridge,
    bridgeAmount,
    handleChangeBridgeAmount,
    insufficientBalance,
    setMaxAmountToBridge,
    swappableAmount,
    setSwappableAmount,
    destinationChain,
    isDelivered,
  } = useBridgeContext();
  const { connectedChainId, account } = useWalletAccount();
  const handleKeyDown = (event: any) => {
    if (event.key === "-" || event.key === "e") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (
      userOftBalances &&
      selectedTokenToBridge &&
      account &&
      isOftBalanceFetched &&
      !isFetchingOftBalance
    ) {
      const targetItem = userOftBalances.find(
        (item) =>
          item.chainId === destinationChain?.id &&
          item.gateWayAddress!.toLowerCase() ===
            selectedTokenToBridge.dstGateway.toLowerCase()
      );

      if (isMounted && targetItem) {
        const swappableAmount = targetItem.swappableAmount;
        const balance = targetItem.gatewayBalance;

        if (swappableAmount) {
          setSwappableAmount(fromWei(swappableAmount.toString()));
        } else if (balance) {
          setSwappableAmount(fromWei(balance.toString()));
        } else {
          setSwappableAmount(0);
        }
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    selectedTokenToBridge,
    destinationChain,
    userOftBalances,
    isOftBalanceFetched,
    isFetchingOftBalance,
  ]);

  useEffect(() => {
    if (isDelivered) {
      // console.log(isDelivered);
      refetchOftBalance();
    }
  }, [isDelivered]);

  return (
    <div className="flex flex-col gap-3 mb-10 px-4 sm:px-0">
      <div className="text-right flex items-center justify-end gap-2">
        <p>Bridgeable Amount:</p>
        {selectedTokenBalance && selectedTokenToBridge && account ? (
          <div className="flex gap-2 items-center">
            <p>{swappableAmount ? formatNumber(Number(swappableAmount)) : 0}</p>
          </div>
        ) : (
          "..."
        )}
      </div>
      <div className="text-right flex items-center justify-end gap-2">
        <p>Available:</p>
        {selectedTokenBalance && selectedTokenToBridge && account ? (
          <div className="flex gap-2 items-center">
            <p>{formatNumber(Number(selectedTokenBalance))}</p>
            <p>{selectedTokenToBridge.tokenName}</p>
            <img
              className="rounded-full "
              width={20}
              height={20}
              src={ChainsLogo[selectedTokenToBridge.chainId]}
            />
          </div>
        ) : (
          "..."
        )}
      </div>
      <div
        className={`bg-[#445b5b] rounded-lg overflow-hidden p-[1px] ${
          !selectedTokenToBridge ||
          (connectedChainId != selectedTokenToBridge.chainId && "opacity-60")
        }`}
      >
        <div className="flex items-center gap-3 bg-[#0c0f12] opacity-[.9] h-[52px] px-3 rounded-[7px]">
          <input
            className="h-full w-full bg-transparent"
            type="number"
            min={0}
            onKeyDown={handleKeyDown}
            value={bridgeAmount ?? ""}
            onChange={(e) => {
              const value = e.target.value;
              if (!value || /^\d*\.?\d{0,6}$/.test(value)) {
                handleChangeBridgeAmount(value);
              }
            }}
            disabled={
              !selectedTokenToBridge ||
              connectedChainId != selectedTokenToBridge.chainId
            }
            placeholder={`${
              !selectedTokenToBridge ? "Please Select Token" : " Amount"
            }`}
          />
          <div
            onClick={() => setMaxAmountToBridge()}
            className="flex select-none items-center justify-center h-8 w-[49px] bg-[#6b5838] rounded text-sm cursor-pointer"
          >
            Max
          </div>
        </div>
      </div>
      <div className="h-3">
        {insufficientBalance && selectedTokenToBridge && account && (
          <div className="text-red-500 -mt-2">Insufficient Balance</div>
        )}
      </div>
    </div>
  );
};

export default BridgeAmountInput;
